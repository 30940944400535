// table style
body.dark-theme .table-light {
  @extend .table-dark;
}


// vis timeline styles:
body.dark-theme .vis-current-time {
  background-color: var(--ra-primary-color);
}

body.dark-theme .vis-grid.vis-minor {
  border-color: #444;
}

body.dark-theme .vis-grid.vis-major {
  border-color: #666;
}

body.dark-theme .vis-labelset .vis-label {
  color: var(--ra-text-color);
}

body.dark-theme .vis-label.vis-nested-group.vis-group-level-unknown-but-gte1 {
  background-color: var(--ra-dark-base-color);
}


// input styles
body.dark-theme .form-check-input {
  background-color: #444;
  border-color: #fff;
}

body.dark-theme select,
body.dark-theme input,
body.dark-theme textarea {
  background-color: var(--ra-base-color);
}

body.dark-theme .form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

body.dark-theme .form-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-color: var(--ra-secondary-color) !important;
  border-color: white !important;
}


//accordion styles
body.dark-theme .accordion-header {
  .accordion-button::after {
    background-size: contain;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  }

  &.collapsed .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  }
}
