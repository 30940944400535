@import "./shared/styles/colors.scss";
@import "./shared/styles/dark-theme-styles.scss";
@import "./shared/styles/forms.scss";
@import "./shared/styles/spacing.scss";
@import "./shared/styles/tables.scss";
@import '@angular/cdk/overlay-prebuilt.css';
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~vis-timeline/dist/vis-timeline-graph2d.min.css";
@import "bootstrap/scss/bootstrap";
@import "node_modules/ngx-spinner/animations/ball-fussion.css";

body {
  height: 100vh;
}

.base-container {
  background-color: var(--ra-dark-base-color-opacity);
}

.title {
  color: var(--ra-primary-color);
  font-weight: 600;
}

.title-container {
  max-height: 10%;
  display: flex;
  flex-direction: row;
}

.modal-title {
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #818589;
}

::-webkit-scrollbar-thumb:hover {
  background: #a9a9a9;
}

* {
  scrollbar-width: auto;
  scrollbar-color: var(--ra-scrollbar-thumb-color) var(--ra-scrollbar-background-color);
}

.page-link {
  color: var(--ra-primary-color) !important;
  font-size: 12px;

  &:focus {
    box-shadow: 0 0 5px var(--ra-dark-base-color-opacity);
  }

  &.active,
  .active > & {
    background-color: var(--ra-primary-color) !important;
    border-color: var(--ra-primary-color) !important;
    color: var(--ra-light-text-color) !important;
  }
}

.form-check-input:checked {
  background-color: var(--ra-secondary-color);
  border: var(--ra-secondary-color);
}

.tab-content {
  > .active {
    height: 100%;
  }
}

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].asc:before {
  font-family: "FontAwesome";
  content: "\f062";
  display: block;
  width: 15px;
  height: 15px;
  float: right;
  margin-left: 0;
}

th[sortable].desc:before {
  font-family: "FontAwesome";
  content: "\f063";
  display: block;
  width: 15px;
  height: 15px;
  float: right;
  margin-left: 0;
}

.text-overflow-dotted {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vis-ltr .vis-label.vis-nested-group .vis-inner {
  padding-left: 0;
  padding-right: 150px;
}

.vis-item.base {
  color: var(--ra-text-color);
  background-color: var(--ra-primary-color-lightest);
  border-color: var(--ra-primary-color-lightest);
  border-width: 3px;
  border-radius: 5px;
}

.vis-item.base-without-task {
  color: var(--ra-text-color);
  background-color: var(--ra-base-color);
  border-color: var(--ra-primary-color-lightest);
  border-width: 3px;
  border-radius: 5px;
}

.vis-item.summary {
  color: var(--ra-text-color);
  background-color: var(--ra-secondary-color-lightest);
  border-color: var(--ra-secondary-color-lightest);
  border-radius: 5px;
}

.vis-foreground .vis-group {
  border: none;
  &.employee {
    border-top: 1px solid var(--ra-dark-base-color);
  }
}

.vis-group-level-unknown-but-gte1 {
  border: none;
}

.vis-label.vis-nested-group.vis-group-level-unknown-but-gte1 {
  &.add span {
    font-family: "FontAwesome";
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
      background-color: var(--ra-dark-base-color);
    }
  }
}

.vis-nesting-group {
  border: none;
}

.vis-labelset .vis-label {
  border: none;
  &.employee {
    border-top: 1px solid var(--ra-dark-base-color);
    font-weight: bold;
  }
}

.vis-timeline {
  border: none;
}

.vis-label.vis-nesting-group {
  &.collapsed:before {
    font-family: "FontAwesome";
    content: "\f054";
    padding: 0 3px;
  }
  &.expanded:before {
    font-family: "FontAwesome";
    content: "\f078";
    padding: 0 3px;
  }
}

button {
  &.dropdown-item {
    font-size: 14px;
  }
}

.modal-create-plan-dialog .modal-content,
.modal-edit-plan-dialog .modal-content {
  width: 50vw; // instead of the default 500px
  transform: translate(-40px, 0); // to recenter horizontally
}

.alert {
  margin: 0;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.primary-btn {
  width: fit-content;
  color: var(--ra-light-text-color) !important;
  background-color: var(--ra-primary-color) !important;
  border-color: var(--ra-primary-color) !important;

  &:hover {
    color: var(--ra-light-text-color);
    background-color: var(--ra-primary-color-light) !important;
    border-color: var(--ra-primary-color-light) !important;
  }

  &:active {
    color: var(--ra-light-text-color) !important;
  }
}

.secondary-btn {
  width: fit-content;
  color: var(--ra-light-text-color) !important;
  background-color: var(--ra-secondary-color) !important;
  border-color: var(--ra-secondary-color) !important;

  &:hover {
    color: var(--ra-light-text-color);
    background-color: var(--ra-secondary-color-light) !important;
    border-color: var(--ra-secondary-color-light) !important;
  }

  &:active {
    color: var(--ra-light-text-color) !important;
  }
}

.remove-icon {
  color: var(--ra-secondary-color);
  cursor: pointer;
  transition: .3s;
  &:hover {
    color: var(--ra-error-color) !important;
  }
}

.disabled-icon {
  opacity: 0.5;
  pointer-events: none;
  &:hover {
    color: var(--ra-secondary-color);
  }
}

.btn:disabled, .btn.disabled {
  color: #f0f0f0;
  opacity: 0.4 !important;
}

.custom-modal-window {
  --bs-modal-width: 50vw;
  .modal-dialog, .modal-content {
    min-width: 500px;
    min-height: 300px;
    max-height: 80vh;
  }
}

.profile-modal-window {
  --bs-modal-width: 40vw;
  .modal-dialog, .modal-content {
    height: 80vh;
    min-width: 500px;
    min-height: 300px;
  }
}

.cancel-edit-icon {
  color: var(--ra-error-color);
  cursor: pointer;
}

.save-edit-icon {
  color: var(--ra-success-color);
  cursor: pointer;
}

.edit-icon {
  color: var(--ra-secondary-color);
  cursor: pointer;
}

.edit-level-icon {
  cursor: pointer;
  color: var(--ra-secondary-color);
  padding-left: 2px;
  font-size: 12px;
}

.search {
  gap: var(--gap-s);
}

.modal-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: var(--gap-s);
  justify-content: flex-end;
}

.accordion {
  --bs-accordion-border-width: 0px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.accordion-body{
  background-color: var(--ra-dark-base-color-opacity);
  border-radius: 0 0 0.5rem 0.5rem;
}

.accordion-button {
  background: none;
  border-radius: 0.5rem;
  &:focus {
    box-shadow: none;
  }

  &:not(.collapsed) {
    background: none;
    color: var(--ra-accordion-header-open-text-color);
  }
}

.accordion-item {
  border-radius: 0.5rem;
}

.accordion-header {
  border-radius: 0.5rem;
  &:not(.collapsed) {
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: var(--ra-accordion-header-open-color);
  }
  background-color: var(--ra-dark-base-color-opacity);
}

.progressbar {
  background-color: var(--ra-secondary-color-lightest);
}

.progress-bar{
  background-color: var(--ra-secondary-color) !important;
}

.hidden {
  display: none;
}

.error-toast {
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 999;
}

.selected-item {
  background-color: var(--ra-primary-color) !important;
}

.ngx-dropdown-container .ngx-dropdown-button {
  background-color: var(--ra-base-color) !important;
  color: var(--ra-text-color) !important;
}

.ngx-dropdown-list-container {
  background-color: var(--ra-base-color) !important;
  
}

.available-items span{
  color: var(--ra-text-color) !important;
}