.bordered-container {
    background-color: var(--ra-base-color);
    min-height: 0;
}

.table-header {
    font-size: 12px;
    position: sticky;
    top: -1px;
}

.table-striped > tbody > tr:nth-of-type(odd),
.table-striped > tbody > tr:nth-of-type(even) {
  &.selected > * {
    --bs-table-bg-state: var(--ra-primary-color-lightest-opacity);
  }
}

.table-hover > tbody > tr:hover {
  &.selected > * {
    --bs-table-bg-state: var(--ra-primary-color-lightest-opacity-hover);
  }
}

.table-striped > tbody > tr:nth-of-type(odd) > *,
.table-striped > tbody > tr:nth-of-type(even) > * {
  &.in-bound {
    --bs-table-bg-type: var(--ra-success-color-lightest);
  }
}

.table-hover > tbody > tr:hover > * {
  &.in-bound {
    --bs-table-bg-state: var(--ra-success-color-light);
  }
}

.table-actions {
  align-items: center;
  gap: var(--gap-l);
}

td {
  font-size: 12px;
  max-width: 70px;
  height: 41px;
  input,
  select {
    height: 2em;
    border-radius: 5px;
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
    &:focus {
      border: 0;
    }
  }
}
