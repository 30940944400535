select.ng-touched.ng-invalid,
input.ng-touched.ng-invalid {
  border-color: var(--ra-error-color) !important;
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--gap-l);
  background-color: var(--ra-dark-base-color-opacity);
  padding: 1.5em;
  border-radius: 0.5rem;
}

.form textarea {
  width: 60%;
}

.form input {
  font-size: 16px;
  border: 1px solid var(--ra-dark-base-color);
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  width: 60%;
  height: 30px;
  &:focus {
    border: 1px solid var(--ra-dark-base-color);
  }
}

.form .range-input-wrapper {
  width: 60%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  input[type="range"] {
    width: 100%;
  }
  .percentage-number-input {
    width: 50px;
  }
}

.form select {
  font-size: 16px;
  border: 1px solid var(--ra-dark-base-color);
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  width: 60%;
  height: 30px;
  &:focus {
    border: 1px solid var(--ra-dark-base-color);
  }
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-l);
}

.form-row .row-label {
  font-weight: bold;
}

.form .form-row .wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 70%;
  gap: var(--gap-m);
}

.form-error {
  color: var(--ra-error-color);
  font-size: 80%;
  text-align: left;
}
