:root {
  --ra-primary-color: #e95a1a;
  --ra-primary-color-light: #e67643;
  --ra-primary-color-lightest: #ebab8d;
  --ra-primary-color-lightest-opacity-hover: #ebab8d66;
  --ra-primary-color-lightest-opacity: #ebab8d4d;

  --ra-secondary-color: #212852;
  --ra-secondary-color-light: #424872;
  --ra-secondary-color-lightest: #b7bbd8;

  --ra-success-color: #008000;
  --ra-success-color-light: #85db85;
  --ra-success-color-lightest: #bdf9bd;

  --ra-error-color: #ff0000;

  --ra-base-color: #ffffff;
  --ra-dark-base-color: #c0c0c0;
  --ra-dark-base-color-opacity: #423f3f13;

  --ra-text-color: #181818;
  --ra-light-text-color: #ffffff;

  --ra-accordion-header-open-text-color: #151515;
  --ra-accordion-header-open-color: #ebab8d;

  --ra-scrollbar-background-color: #f0f0f0;
  --ra-scrollbar-thumb-color: #c0c0c0;
}

.dark-theme {
  --ra-primary-color: #f0804c;
  --ra-primary-color-light: #e49069;
  --ra-primary-color-lightest: #ebab8d;

  --ra-secondary-color: #7889e0;
  --ra-secondary-color-light: #404e91;
  --ra-secondary-color-lightest: #2a346d;

  --ra-success-color: #69d469;
  --ra-success-color-light: #85e285;
  --ra-success-color-lightest: #dbfadb;

  --ra-error-color: #ff0000;

  --ra-base-color: #303030;
  --ra-dark-base-color: #1e1e1e;
  --ra-dark-base-color-opacity: #12121275;

  --ra-text-color: #ffffff;
  --ra-light-text-color: #ffffff;

  --ra-accordion-header-open-text-color: #151515;
  --ra-accordion-header-open-color: #ebab8d;

  --ra-scrollbar-background-color: #353535;
  --ra-scrollbar-thumb-color: #c0c0c0;
}
